import React from 'react';
import Hero from "../component/Hero";
import AboutUs from "../component/AboutUs";
import Leverage from "../component/Leverage";
import Services from "../component/Services";
import Team from "../component/Team";
import GetInTouch from "../component/GetInTouch";
import Footer from "../component/Footer";


function LandingPage(props) {
    return (
        <>
            <Hero/>
            <AboutUs/>
            <Leverage/>
            <Services/>
            <Team/>
            <GetInTouch/>
            <Footer/>
        </>
    );
}

export default LandingPage;
