import React, {useEffect, useRef, useState} from 'react';

import styled, {keyframes} from "styled-components";

const FadeUpText = styled.div`
  opacity: 0;
   transition: opacity 0.1s color 3s;
  &.fade-out-visible-1 {
    opacity: 1;
      transition-delay: 0.5s;
  }
  &.fade-out-visible-2 {
    opacity: 1;
    transition-delay: 0.75s;
  }
  &.fade-out-visible-3 {
    opacity: 1;
    transition-delay: 1s;
  }
  &.fade-out-visible-4 {
    opacity: 1;
    transition-delay: 1.25s;
  }
  &.fade-out-visible-5 {
  opacity: 1;
    transition-delay: 1.5s;
  }
  &.fade-out-visible-6 {
    opacity: 1;
    transition-delay: 1.75s;
  }
  &.fade-out-visible-7 {
    opacity: 1;
    transition-delay: 2s;
  }
  &.fade-out-visible-8 {
    opacity: 1;
    transition-delay: 2.25s;
  }
  &.fade-out-visible-9 {
  opacity: 1;
    transition-delay: 2.5s;
  }
  &.fade-out-visible-10 {
    opacity: 1;
    transition-delay: 2.75s;
  }
  &.fade-out-visible-11 {
    opacity: 1;
    transition-delay: 3s;
  }
  &.change-color {
    color: red;
  }
`;


function Leverage() {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.unobserve(entry.target);
                }
            },
            {
                root: null,
                rootMargin: "0px",
                threshold: 0.1,
            }
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, []);

    return (
        <div className={'leverage-container py-4'}>
            <div className={'container'}>
                <div className={'leverage-inner-container'}>

                    <p className={'leverage-text'}>
                        <FadeUpText ref={ref} className={isVisible && "square fade fade-out-0"}>leverage&nbsp;
                        </FadeUpText>

                        <FadeUpText ref={ref} className={isVisible && "square fade fade-out-1"}>Rift Brand's
                        </FadeUpText>
                    </p>
                    <p className={'leverage-text'}>
                        <FadeUpText ref={ref} className={isVisible && "square fade fade-out-2"}>
                            extensive&nbsp;
                        </FadeUpText>
                        <FadeUpText ref={ref} className={isVisible && "square fade fade-out-3"}>
                            Branding&nbsp;
                        </FadeUpText>
                        <FadeUpText ref={ref} className={isVisible && "square fade fade-out-4"}>
                            And Marketing
                        </FadeUpText>
                    </p>
                    <p className={'leverage-text'}>
                        <FadeUpText ref={ref} className={isVisible && "square fade fade-out-5"}>
                            expertise&nbsp;
                        </FadeUpText>
                        <FadeUpText ref={ref} className={isVisible && "square fade fade-out-6"}>
                            to&nbsp;
                        </FadeUpText>
                        <FadeUpText ref={ref} className={isVisible && "square fade fade-out-7"}>
                            maximize&nbsp;
                        </FadeUpText>
                        <FadeUpText ref={ref} className={isVisible && "square fade fade-out-8"}>
                            your&nbsp;
                        </FadeUpText>
                    </p>
                    <p className={'leverage-text'}>
                        <FadeUpText ref={ref} className={isVisible && "square fade fade-out-9"}>
                            businesses&nbsp;
                        </FadeUpText>
                        <FadeUpText ref={ref} className={isVisible && "square fade fade-out-10"}>
                            potential&nbsp;
                        </FadeUpText>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Leverage;
