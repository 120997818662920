import React from 'react';

function AboutUs(props) {
    return (
        <div className={'container'}>
            <div className="about-us-container py-5">
                <p className={'about-us-heading'}>ABOUT US</p>
                <p className={'about-us-text'}>Rift Brands is your full-cycle Branding partner, from concept to production and beyond. We're not just
                    marketing specialists, we're the team that helps startups and enterprises build a captivating online presence and accelerate growth. 
        Led by founder Kevin Duterval, we guide you through the
                    entire process and shape your ideas into ready-to-go solutions. With our expertise across multiple industries,
                    we'll help you build compelling brands and innovative products that fosters your company's success.</p>
            </div>
        </div>
    );
}

export default AboutUs;
