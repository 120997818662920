import React from 'react';

function HeroBody(props) {
    return (
        <div className={'zoomOut delay-2s hero-body'}>
            <div className={"container"}>
                <p className={'hero-body-heading'}>Rift Brands</p>

                <p className={'hero-body-meta'}>
                   Empower Your Business with Strategic Brand Development and<br/> Our Award-Winning Marketing Solutions
                
                </p>

                <div className={'hero-btn-div py-4'}>
                    <a href={'#talk'} className={'btn hero-body-btn lets-talk-btn'}>
                        Let's talk
                    </a>
                    <a href="#services" className={'btn hero-body-btn our-services-body-btn'}>
                        Our Services
                    </a>
                </div>
            </div>
        </div>
    );
}

export default HeroBody;
