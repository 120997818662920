import React from 'react';
import Veba from "./Veba";

import {Swiper, SwiperSlide} from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import {Autoplay, Pagination} from "swiper";

function Services() {
    const services = [{
        names: ["Branding", " & Strategy"],
        services: [
            "Branding Consultation",
            "Brand Positioning and Differentiation",
            "Market Research and Analysis",
            "Creative Design and Visual Branding",
        
        ],
    },
        {
            names: ["Digital Marketing", "& Optimization"],
            services: [
                "Social Media Mgmt & Content Creation",
                "Search Engine Optimization (SEO)",
                "Online Advertising Campaigns",
                "Conversion Rate Optimization (CRO)"
            ],
        },
        {
            names: ["Data Analysis", "& Insights"],
            services: [
                "Marketing Analytics and Reporting",
                "Customer Behavior Insights",
                "Data Mining",
                "Data-driven Decision Making",
               
            ],
        },
    ]
    return (
        <div id={'services'} className={'py-5'}>
            <div className="container">

                <div className="services-inner-container">
                    <p className={'about-us-heading'}>OUR SERVICES</p>
                    <div className={'veba-container'}>
                        <Veba/>
                    </div>
                </div>
                <div className="services-lg row">
                    {services.map((service) => {
                        return (
                            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 col-12 mb-4">
                                <div className="service-container shadow">
                                    <div className="service-name-container">
                                        {service.names.map((name) => {
                                            return (
                                                <p>{name}</p>
                                            )
                                        })}
                                    </div>
                                    <div className="service-service-container">
                                        {service.services.map((service) => {
                                            return (
                                                <p>{service}</p>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>

                <div className="services-sm">
                    <Swiper pagination={true}
                            modules={[Pagination]} className="mySwiper">
                        {services.map((service) => {
                            return (
                                <SwiperSlide>
                                    <div className={'service-container-outer'}>
                                        <div className="service-container shadow-sm">
                                            <div className="service-name-container">
                                                {service.names.map((name) => {
                                                    return (
                                                        <p>{name}</p>
                                                    )
                                                })}
                                            </div>
                                            <div className="service-service-container">
                                                {service.services.map((service) => {
                                                    return (
                                                        <p>{service}</p>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </div>

            </div>
        </div>
    );
}

export default Services;
