import React from 'react';
import Navbar from "./Navbar";
import HeroBody from "./HeroBody";
import Nova from "./Nova";

function Hero() {
    return (
        <div className={'hero'}>
            <Navbar/>
            <HeroBody/>
            <Nova/>
        </div>
    );
}

export default Hero;