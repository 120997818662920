import React from 'react';
import logo from "../assets/BlackRA.png";
import {useNavigate} from "react-router-dom";

function Navbar() {
    const navigate = useNavigate()

    return (
        <div className={'navbar'}>
            <div className={"container"}>
                <img src={logo} className={'navbar-logo'}/>

                <a className={'btn our-services-btn'} href="#services">Our services</a>
                <button
                    onClick={() => {
                        navigate('/subscribe/')
                    }}
                    className={'btn navbar-newsletter-btn'}>
                    Join our newsletter
                </button>
            </div>
        </div>
    );
}

export default Navbar;