import React from 'react';
import logo from '../assets/BlackRA.png';
import { useNavigate } from 'react-router-dom';

function Footer() {
  const navigate = useNavigate();

  return (
    <div className="container">
      <div className="row g-0 footer-desc-container">
        <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 col-12 mb-1">
          <img src={logo} className="footer-logo" alt="Rift Brands" />
          <p className="footer-newsletter-text">Join our newsletter</p>
          <button
            onClick={() => {
              navigate('/subscribe/');
            }}
            className="btn footer-subscribe-btn"
          >
            Subscribe
          </button>
          <p className="footer-desc-text">
            Gain a competitive edge with the latest branding and marketing breakthroughs and updates - all in a 5 minute
            email. Our Branding experts sift through the clutter, honing in on the news that truly matters.
          </p>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 col-4 mb-1">
          <div className="footer-info-container">
            <div className="footer-info-inner-container">
              <p className="footer-newsletter-text">Address</p>
              <p className="footer-info-text">Miami, FL</p>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-8 col-xs-8 col-8 mb-1">
          <div className="footer-info-container">
            <div className="footer-info-inner-container">
              <p className="footer-newsletter-text">Contact</p>
              <p className="footer-info-text">
                Email: business@riftbrands.com <br /> Phone: (561) 400-7771
              </p>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="footer-bottom">
        <p className="footer-bottom-mention">©2023 Rift Brands, LLC. All rights reserved.</p>
        <div style={{ display: 'flex' }}>
          <p className="footer-bottom-text">Privacy Policy</p>
          <p className="footer-bottom-text">Terms of Service</p>
          <p className="footer-bottom-text">Cookie Settings</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
