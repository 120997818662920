import React from 'react';

function GetInTouch() {
    return (
        <div id={'talk'} className={'pb-5'}>
            <div className="container">
                <div className="get-in-touch-container">
                    <p className={'about-us-heading'}>LET'S GET IN TOUCH</p>

                    <div className="get-in-touch-fields-container">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 my-2">
                                <input className={'get-in-touch-input form-control'}
                                       placeholder={'Full Name'}
                                />
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12 my-2">
                                <input className={'get-in-touch-input form-control'}
                                       placeholder={'Subject'}
                                />
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12 my-2">
                                <input className={'get-in-touch-input form-control'}
                                       placeholder={'Your email'}
                                />
                            </div>

                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 my-2">
                            <textarea rows="5" className={'get-in-touch-input form-control'}
                                      placeholder={'How can we help you?'}
                            />
                            </div>

                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 my-3">
                                <p style={{textAlign: "left", color: "#9CA3AF"}}>
                                    What is the budget for this project</p>
                                <select className="get-in-touch-input get-in-touch-select form-select" aria-label="Default select example">
                                    <option selected>less then $5,000</option>
                                    <option value="1">$5,000 - $10,000</option>
                                    <option value="2">$10,000 - $20,000</option>
                                    <option value="3">$20,000 - $40,000</option>
                                    <option value="3">$40,000+</option>
                                </select>
                            </div>

                        </div>

                        <button className={'btn let-get-in-touch-btn'}>
                            Send
                        </button>

                    </div>
                </div>
            </div>
        </div>
    ); 
}

export default GetInTouch;
