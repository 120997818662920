import React from 'react';
import subscribeimg from '../assets/subscribe-img.avif';

function SubscribePage() {
    return (
        <div className="subscribe-container">
            <div className="subscribe-inner-container">
                <img src={subscribeimg}
                     className={'subscribe-img'}
                />
                <p className={'subscribe-heading'}>Over The Shoulder</p>
                <p className={'subscribe-text'}>



                         Granting you access to our brand-building notepad: Insights from running successful brands, 
                         spontaneous advertising ideas, and step-by-step implementation strategies. Discover with us, Rift Brands.

                         
           
                </p>

                <div className={'enter-email-container'}>
                    <input className={'form-control'} placeholder={'Enter your email'}/>
                    <div className={'enter-email-subscribe-btn'}>
                        <p>Subscribe</p>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default SubscribePage;
