import './App.css';
import LandingPage from "./pages/LandingPage";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import SubscribePage from "./pages/SubscribePage";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path={"/"} element={<LandingPage/>}/>

                <Route exact path={"/subscribe/"} element={<SubscribePage/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
