import React from 'react';
import team1 from '../assets/1.png';
import team2 from '../assets/2.png';
import team3 from '../assets/3.png';

function Team() {
    const team_members = [
        {
            name: "Kevin Duterval",
            photo: team1,
            role: "CEO",
            description: "ceo",
            links: [
                "https://facebook.com/",
                "https://twitter.com/",
                "https://portfolio.com/",
            ],
        },
        {
            name: "Daniel Hoffman",
            photo: team2,
            role: "HEAD OF OPERATIONS",
            description: "head",
            links: [
                "https://facebook.com/",
                "https://twitter.com/",
                "https://portfolio.com/",
            ],
        },
        {
            name: "Sarah Ross",
            photo: team3,
            role: "Creative Director",
            description: "lead",
            links: [
                "https://facebook.com/",
                "https://twitter.com/",
                "https://portfolio.com/",
            ],
        },
    ];

    return (
        <div className={'py-5'}>
            <div className="container">
                <div className="team-container">
                    <p className={'about-us-heading'}>MEET THE TEAM</p>

                    <div className="row">
                        {team_members.map((member) => {
                            return (
                                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 col-12">
                                    <div className={'team-member-container'}>
                                        <img className={'team-member-photo'}
                                             src={member.photo}/>
                                    </div>
                                    <p className={'team-member-name'}>{member.name}</p>
                                    <p className={'team-member-role'}>{member.role}</p>
                                </div>
                            )
                        })}
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Team;
